<template>
  <div class="constructionbillList">
    <!-- <el-breadcrumb separator="/" style="margin: 10px 0px">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>待开运单</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 表格区域 -->

    <div style="max-height:84.5vh">
      <!-- <el-tabs v-model="activeCmpt" @tab-click="cmptChange">
        <el-tab-pane label="小程序运单" name="1"></el-tab-pane>
        <el-tab-pane label="其他运单" name="2"></el-tab-pane>
      </el-tabs> -->
      <div>
        <div class="topbtns">
          <!-- <el-button icon="el-icon-download" type="primary" size="medium" @click="exportExcel">导出表格</el-button> -->
          <el-button type="primary" size="medium" @click="sendOrderStore">发往运单库</el-button>
        </div>

        <!-- <el-form class="searchForm" :model="searchForm" ref="searchForm">

          <div style="display: flex; flex-wrap: wrap;">
            <div style="display: flex; flex-wrap: wrap;">
              <el-form-item label="企业名称：" prop="keyword" label-width="120px">
                <el-input style="width: 180px;" v-model="searchForm.companyName" placeholder="请输入企业名称"></el-input>
              </el-form-item>
              <el-form-item label="司机手机号：" prop="keyword" label-width="120px">
                <el-input style="width: 180px;" v-model="searchForm.driverPhone" placeholder="请输入司机手机号">
                </el-input>
              </el-form-item>
            </div>
            <div style="display: flex; flex-wrap: wrap;">
              <el-form-item label="收款人手机号：" prop="keyword" label-width="120px">
                <el-input style="width: 180px;" v-model="searchForm.receivePhone" placeholder="请输入收款人手机号">
                </el-input>
              </el-form-item>
              <el-form-item label="车牌号：" prop="keyword" label-width="120px">
                <el-input style="width: 180px;" v-model="searchForm.carNumber" placeholder="请输入车牌号">
                </el-input>
              </el-form-item>

              <el-form-item label-width="10px">
                <div style="white-space:nowrap;">
                  <el-button type="primary" size="medium" @click="search('searchForm')" icon="el-icon-search">搜索</el-button>
                  <el-button type="primary" size="medium" icon="el-icon-delete" @click="resetForm('searchForm')">清空</el-button>
                  <div @click="isShowSelect" style="color: #409EFF; display: inline-block; margin-left: 30px; cursor:pointer;">
                    <span>{{showSelect ? '收起' : '展开'}}</span><i :class="showSelect ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-left: 10px; line-height: 30px"></i>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
          <el-form-item label="运单号：" prop="keyword" label-width="130px">
          <el-input v-model="searchForm.wayBillID" placeholder="请输入运单号">
          </el-input>
        </el-form-item>
          <el-collapse-transition>
            <div style="display: flex; flex-wrap: nowrap;" v-show="showSelect">
              <el-form-item label="起始地：" label-width="120px">
                <el-input style="width: 180px;" v-model="searchForm.startAddress" placeholder="请输入起始地">
                </el-input>
              </el-form-item>
              <el-form-item label="到达地：" label-width="120px">
                <el-input style="width: 180px;" v-model="searchForm.arriveAddress" placeholder="请输入到达地">
                </el-input>
              </el-form-item>
              <el-form-item label="场景类型：" prop="keyword" label-width="130px">

          <el-select @change="changeType" v-model="searchForm.type" placeholder="请选择场景类型" style="width: 208px">
            <el-option v-for="item in sceneList" :key="item.Code" :label="item.Name" :value="item.Code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="进度状态：" prop="keyword" label-width="130px">
          <el-select @change="changeProgress" v-model="searchForm.status" placeholder="请选择进度状态" style="width: 208px">
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
              <el-form-item label="签收时间：" prop="keyword" label-width="120px">
                <el-date-picker v-model="searchForm.time" type="datetimerange" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
            </div>
          </el-collapse-transition>
        </el-form> -->
        <el-table :data="tableData" row-key="SettleID" ref="table" @selection-change="tableSelectionChange"
          v-loading="loading" :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
          <el-table-column type="selection" :reserve-selection="true" align="center" fixed="left" width="50">
          </el-table-column>
          <el-table-column fixed align="center" width="50" prop="sindex" label="序号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="SettleID" label="待选开票申请编号" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="InvoiceTypeName" label="发票类型" width="150" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="TaskTypeName" label="业务类型" width="100" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="TaskSceneTypeName" label="场景类型" width="100" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" width="100" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="SettleStartDate" label="结算起始时间" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="SettleEndDate" label="结算终止时间" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="SubmitTime" label="提交时间" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="OrderCounts" label="总计运单数量" width="100" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="JobTaskName" label="任务名称" width="100" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="JobAddress" label="任务地址" width="100" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="AdminNmae" label="管理人" width="100" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="JobTaskType" label="计量员类型" width="100" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="TransportCosts" label="运费" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.TransportCosts | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="120">
            <template slot-scope="scope">
              <el-button type="primary" size="small" :disabled="btnDisabled" @click="showSettleDialog(scope.row)">详情
              </el-button>
              <!-- <el-button type="primary" size="small" :disabled="btnDisabled" @click="editOrder(scope.row)">详情</el-button> -->
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.pageIndex" :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper" :total="pagination.totalCount">
        </el-pagination>
      </div>
    </div>
    <!-- 编辑运单dialog -->
    <!-- <el-dialog class="vehicleType" :visible.sync="showEditDialog" width="950px" style="margin-top: 1vh;">
      <span slot="title" class="dialog-title">
        运单详情
      </span>
      <div class="body">
        <div style="height: 60vh;overflow-y:auto">

          <el-form class="addForm" :model="addForm" ref="addForm" label-width="152px">

            <el-form-item label="运单号" prop="CarriageBillID">
              <el-input disabled v-model="addForm.CarriageBillID" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="发票类型" prop="InvoiceTypeName">
              <el-input disabled v-model="addForm.InvoiceTypeName" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="业务类型" prop="TaskTypeName">
              <el-input disabled v-model="addForm.TaskTypeName" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="场景类型" prop="TaskSceneName">
              <el-input disabled v-model="addForm.TaskSceneName" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="货主单位" prop="GoodsOwnerName">
              <el-input disabled v-model="addForm.GoodsOwnerName" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="司机姓名" prop="DriverName">
              <el-input disabled v-model="addForm.DriverName" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="司机手机号码" prop="DriverPhone">
              <el-input disabled v-model="addForm.DriverPhone" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="收款人姓名" prop="PayeeName">
              <el-input disabled v-model="addForm.PayeeName" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="收款人手机号码" prop="PayeePhone">
              <el-input disabled v-model="addForm.PayeePhone" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="车牌" prop="CarNumber">
               <el-input disabled v-model="addForm.CarNumber" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="装货净重(吨)" prop="LoadNetWeight">
               <el-input disabled v-model="addForm.LoadNetWeight" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="货品名称" prop="GoodsName">
              <el-input disabled v-model="addForm.GoodsName" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="货品单位" prop="GoodsUnit">
              <el-input disabled v-model="addForm.GoodsUnit" placeholder=""></el-input>
            </el-form-item>
             <el-form-item label="装货时间" prop="LoadingDatetime">
              <el-date-picker disabled v-model="addForm.LoadingDatetime" type="datetime" format="yyyy-MM-dd HH:mm" placeholder="请选择装货时间" value-format="yyyy-MM-dd HH:mm">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="签收时间" prop="SignDatetime">
              <el-date-picker disabled v-model="addForm.SignDatetime" type="datetime" format="yyyy-MM-dd HH:mm" placeholder="请选择签收时间" value-format="yyyy-MM-dd HH:mm">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="起始地" prop="originAddress">
              <el-input disabled v-model="addForm.OriginAddress" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="到达地" prop="arriveAddress">
              <el-input disabled v-model="addForm.ArriveAddress" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="司机装货数量" prop="DriverAllotCount">
              <el-input disabled v-model="addForm.DriverAllotCount" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="司机运输单价(人民币)" prop="DriverCarryPrice">
              <el-input disabled v-model="addForm.DriverCarryPrice" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="运费" prop="CarriageTotalPrice">
              <el-input disabled v-model="addForm.CarriageTotalPrice" placeholder='数字精确到小数点后两位;"数量x单价=运费"'></el-input>
            </el-form-item>

             <el-form-item label="服务费/税费" prop="ExpensesOfTaxation">
              <el-input disabled v-model="addForm.ExpensesOfTaxation" placeholder="数字精确到小数点后两位"></el-input>
            </el-form-item>
             <el-form-item label="运单总成本" prop="TotalTransCost">
              <el-input disabled v-model="addForm.TotalTransCost" placeholder="数字精确到小数点后两位"></el-input>
            </el-form-item>
            <el-form-item label="运输总成本" prop="TotalAmount">
              <el-input disabled v-model="addForm.TotalAmount" placeholder="数字精确到小数点后两位"></el-input>
            </el-form-item>
            <el-form-item label="卸货净重(吨)" prop="UnloadNetWeight">
              <el-input disabled v-model="addForm.UnloadNetWeight" placeholder="数字精确到小数点后两位"></el-input>
            </el-form-item>

            <el-form-item label="允许损耗率(%)" prop="AllowLossRate">
              <el-input disabled v-model="addForm.AllowLossRate" placeholder=""></el-input>
            </el-form-item>
           
            <el-form-item label="溯源运单号" prop="OriginalWayBillID">
              <el-input disabled v-model="addForm.OriginalWayBillID" placeholder=""></el-input>
            </el-form-item>
          </el-form>

     
        </div>
      </div> -->
    <!-- <span slot="footer">
        <el-button size="medium" @click="cancelModel">取消</el-button>
        <el-button type="primary" size="medium" @click="editRow('addForm')">确认</el-button>
      </span> -->
    <!-- </el-dialog> -->
    <!-- 明细弹框-->
    <!-- 编辑运单dialog -->
    <el-dialog class="vehicleType" :visible.sync="settleDialog" width="1200px" style="margin-top: 1vh;">
      <span slot="title" class="dialog-title">
        运单明细
      </span>
      <!-- <div class="info">
              <div style="margin-right: 60px">{{settleInfo.DriverName}}</div>
              <div style="margin-right: 60px">{{settleInfo.DriverPhone}}</div>
              <div>{{settleInfo.CarNumber}}</div>
            </div> -->
      <div class="body">

        <el-table max-height="570" :data="settleTableData" v-loading="settleLoading"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
          <el-table-column fixed align="center" width="50" prop="sindex" label="序号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" width="200" prop="CarriageBillID" label="待选运单号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" width="150" prop="InvoiceTypeName" label="发票类型" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" width="100" prop="TaskTypeName" label="业务类型" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" width="100" prop="TaskSceneName" label="场景类型" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="DriverName" label="司机姓名" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="DriverPhone" width="120" label="司机手机号码" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="DriverIdCard" width="180" label="司机身份证号码" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="PayeeName" width="120" label="收款人姓名" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="PayeePhone" width="120" label="收款人手机号码" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="PayeeIdCard" width="180" label="收款人身份证号码" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="CarNumber" width="120" label="车牌" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="LoadNetWeight" width="120" label="装货净重(吨)" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="GoodsName" label="货品名称" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="GoodsUnit" label="货品单位" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="LoadingDatetime" width="180" label="装货时间" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="SignDatetime" width="200" label="签收时间" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="OriginAddress" width="200" label="起始地" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="ArriveAddress" width="200" label="到达地" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="DriverAllotCount" width="180" label="司机装货数量" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="DriverCarryPrice" width="200" label="司机运输单价(人民币)" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="CarriageTotalPrice" width="120" label="运费" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="ExpensesOfTaxation" width="120" label="服务费/税费" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="TotalAmount" width="120" label="运单总金额" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="TotalTransCost" width="120" label="运输总成本" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="UnloadNetWeight" width="120" label="卸货净重(吨)" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="OriginalWayBillID" label="溯源运单号" width="120" show-overflow-tooltip>
          </el-table-column>
          <!-- <el-table-column align="center" prop="TransportCosts" label="运费"  show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{scope.row.TransportCosts | formatMoney}}
              </div>
            </template>
          </el-table-column> -->
        </el-table>

        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleSettleCurrentChange"
          :current-page.sync="settlePagination.pageIndex" :page-size="settlePagination.pagesize"
          layout="total, prev, pager, next, jumper" :total="settlePagination.totalCount">
        </el-pagination>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getConstructionBillList, getConstructionBillDetail, getSettleDetail, sendOrderStore } from '@/api/waybill/otherAndAllBill/index'
import { getDataDict } from '@/api/common/common'
import { host } from '@/api/config/host'
export default {
  data() {


    return {
      host: host + '/api/Image/Upload/Img', // 上传图片的地址
      pagination: { //分页控件相关参数
        pageIndex: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        totalCount: 0, //总条数
      },
      addForm: {
        CarriageBillID: '',
        InvoiceTypeName: '',
        TaskTypeName: '',
        TaskSceneName: '',
        GoodsOwnerName: '',
        DriverName: '',
        DriverPhone: '',
        PayeeName: '',
        PayeePhone: '',
        CarNumber: '',
        LoadNetWeight: '',
        GoodsName: '',
        GoodsUnit: '',
        LoadingDatetime: '',

        SignDatetime: '',
        originAddress: '',
        arriveAddress: '',
        DriverAllotCount: '',
        DriverCarryPrice: '',
        CarriageTotalPrice: '',
        ExpensesOfTaxation: '',
        TotalTransCost: '',
        TotalAmount: '',
        UnloadNetWeight: '',
        AllowLossRate: '',
        OriginalWayBillID: '',

      },

      // 搜索表单
      searchForm: {
        companyName: '', // 企业名称
        driverPhone: '', // 司机手机号
        receivePhone: '', // 收款人手机号
        carNumber: '', // 车牌
        // wayBillID: '', // 运单号
        time: '',// 时间
        //  type: '01', // 场景类型 默认为查找承运业务
        //  status: '0', // 进度状态
        startAddress: '', // 起始地
        arriveAddress: '' // 到达地
      },
      tableData: [], //表格数据
      sceneList: [], //场景类型
      statusList: [
        {
          value: '0',
          label: '全部'
        },
        {
          value: '1',
          label: '未完成流程'
        },
        {
          value: '2',
          label: '已完成流程'
        }
      ], // 进度状态
      // 是否禁用提交按钮
      // showSubmit: false,
      loading: false,
      settleLoading: false, // 结算单表格
      showEditDialog: false, // 显示编辑弹框
      settleDialog: false, // 明细弹框
      goodsUnitList: [], // 货品单位数据字典
      settlePagination: { //分页控件相关参数   明细的分页
        pageIndex: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        totalCount: 0, //总条数
      },
      settleTableData: [], //明细数据表格
      settleInfo: {
        SettleID: '',
        JobTaskID: '',
        DriverId: '',
        DriverPhone: '',
        CarNumber: '',
        DriverName: '',
      }, // 要加载的结算信息id  用于分页加载数据
      selectedTableItemList: [], // 要发往运单库的数据
      btnDisabled: false, // 各种按钮禁用
    }
  },

  methods: {
    // 隐藏/显示筛选框
    isShowSelect() {
      this.showSelect = !this.showSelect
    },
    //搜索
    search(formName) {
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //搜索前初始化page
      this.pagination.pageIndex = 1
      this.getConstructionBillList()
    },

    //清空
    resetForm(formName) {
      // this.searchForm = {
      //   companyName: '', // 企业名称
      //   driverPhone: '', // 司机手机号
      //   receivePhone: '', // 收款人手机号
      //   carNumber: '', // 车牌
      //   // wayBillID: '', // 运单号
      //   time: [],// 时间
      //   // type: '01', // 场景类型 默认为查找承运业务
      //   //  status: '0', // 进度状态
      //   startAddress: '', // 起始地
      //   arriveAddress: '' // 到达地 
      // }
      this.pagination.pageIndex = 1
      this.getConstructionBillList()
    },
    //导出excel
    exportExcel() {
      let params = {
        //  taskType: this.searchForm.type,
        //   wayBillStatus: this.searchForm.status, 
        // driverPhone: this.searchForm.driverPhone,
        // carNumber: this.searchForm.carNumber,
        // goodsOwnerName: this.searchForm.companyName,
        // originAddress: this.searchForm.startAddress,
        // arriveAddress: this.searchForm.arriveAddress,
        // payeePhone: this.searchForm.receivePhone,
        // taskSceneTypeID: 0,
        // importTimeStart: this.searchForm.time[0],
        // importTimeEnd: this.searchForm.time[1]
      }
      mpAllBillDwon(params).then(res => {
        window.location.href = res.data
      })
    },
    // 编辑运单弹框
    // editOrder(row) {
    //   const params = {
    //     settleId: row.SettleID
    //   }
    //   this.btnDisabled = true
    //   getConstructionBillDetail(params).then(res => {

    //    // 回显运单数据
    //   this.addForm = {
    //     CarriageBillID: res.data.WaybillID,
    //     InvoiceTypeName: res.data.InvoiceTypeName,
    //     TaskTypeName: res.data.TaskTypeName,
    //     TaskSceneName: res.data.TaskSceneName,
    //     GoodsOwnerName: res.data.GoodsOwnerName,
    //     DriverName: res.data.DriverName,
    //     DriverPhone: res.data.DriverPhone,
    //     PayeeName: res.data.PayeeName,
    //     PayeePhone: res.data.PayeePhone,
    //     CarNumber: res.data.CarNumber,
    //     LoadNetWeight: res.data.LoadNetWeight,
    //     GoodsName: res.data.GoodsName,
    //     GoodsUnit: res.data.GoodsUnit,
    //     LoadingDatetime: res.data.LoadingDatetime,

    //     SignDatetime: res.data.SignDatetime,
    //     OriginAddress: res.data.OriginAddress,
    //     ArriveAddress: res.data.ArriveAddress,
    //     DriverAllotCount: res.data.DriverAllotCount,
    //     DriverCarryPrice: res.data.DriverCarryPrice,
    //     CarriageTotalPrice: res.data.CarriageTotalPrice,
    //     ExpensesOfTaxation: res.data.ExpensesOfTaxation,
    //     TotalTransCost: res.data.TotalTransCost,
    //     TotalAmount: res.data.TotalAmount,
    //     UnloadNetWeight: res.data.UnloadNetWeight,
    //     AllowLossRate: res.data.AllowLossRate,
    //     OriginalWayBillID: res.data.OriginalWayBillID,
    //   }
    //   this.showEditDialog = true
    //   this.btnDisabled = false
    //   }).finally(()=> {
    //     this.btnDisabled = false
    //   })

    // },
    // 显示明细弹框
    showSettleDialog(row) {
      this.settleInfo = {

        SettleID: row.SettleID,
        JobTaskID: row.JobTaskID,
        DriverId: row.DriverId,
        DriverPhone: row.DriverPhone,
        CarNumber: row.CarNumber,
        DriverName: row.DriverName,
      }
      const params = {
        settleId: row.SettleID,
        // jobTaskId: row.JobTaskID,
        // driverId: row.DriverId,
        pageIndex: this.settlePagination.pageIndex,
        pageSize: this.settlePagination.pagesize,

      }
      this.settleLoading = true
      this.btnDisabled = true
      getConstructionBillDetail(params).then(res => {
        this.settleDialog = true
        this.settleLoading = false
        this.settleTableData = res.data.DataList
        let i = 1;
        this.settleTableData.forEach((element) => {
          element.sindex =
            (this.settlePagination.pageIndex - 1) * this.settlePagination.pagesize + i++;
        });
        this.settlePagination.totalCount = res.data.TotalCount
      }).finally(() => {
        this.loading = false;
        this.btnDisabled = false
      })
    },
    // // 切换场景类型
    // changeType(val) {
    //   this.searchForm.type = val
    //   this.search()
    // },
    // // 切换进度状态
    // changeProgress(val) {
    //   this.searchForm.status = val
    //   this.search()
    // },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: e,
        //  taskType: this.searchForm.type,
        //  wayBillStatus: this.searchForm.status,
        // driverPhone: this.searchForm.driverPhone,
        // carNumber: this.searchForm.carNumber,
        // goodsOwnerName: this.searchForm.companyName,
        // originAddress: this.searchForm.startAddress,
        // arriveAddress: this.searchForm.arriveAddress,
        // payeePhone: this.searchForm.receivePhone,
        // taskSceneTypeID: 0,
        // importTimeStart: this.searchForm.time[0],
        // importTimeEnd: this.searchForm.time[1]
      }
      getConstructionBillList(params).then(res => {
        this.tableData = res.data.DataList
        let i = 1;
        this.tableData.forEach((element) => {
          element.sindex =
            (this.pagination.pageIndex - 1) * this.pagination.pagesize + i++;
        });
        this.pagination.totalCount = res.data.TotalCount
      }).finally(() => {
        this.loading = false;
      })
    },
    // 明细弹框分页控件页码change事件回调
    handleSettleCurrentChange(e) {
      const params = {
        settleId: this.settleInfo.SettleID,
        // jobTaskId: this.settleInfo.JobTaskID,
        // driverId: this.settleInfo.DriverId,
        pageIndex: e,
        pageSize: this.settlePagination.pagesize,
      }
      this.settleLoading = true
      getConstructionBillDetail(params).then(res => {
        this.settleTableData = res.data.DataList
        let i = 1;
        this.settleTableData.forEach((element) => {
          element.sindex =
            (this.settlePagination.pageIndex - 1) * this.settlePagination.pagesize + i++;
        });
        this.settleLoading = false
        this.settlePagination.totalCount = res.data.TotalCount
      }).finally(() => {
        this.settleLoading = false
      })
    },
    //获取工运宝运单列表
    getConstructionBillList() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.pageIndex,
        //  taskType: this.searchForm.type,
        //  wayBillStatus: this.searchForm.status,
        // driverPhone: this.searchForm.driverPhone,
        // carNumber: this.searchForm.carNumber,
        // goodsOwnerName: this.searchForm.companyName,
        // originAddress: this.searchForm.startAddress,
        // arriveAddress: this.searchForm.arriveAddress,
        // payeePhone: this.searchForm.receivePhone,
        // taskSceneTypeID: 0,
        // importTimeStart: this.searchForm.time[0],
        // importTimeEnd: this.searchForm.time[1]
      }
      this.loading = true;
      getConstructionBillList(params).then(res => {
        this.tableData = res.data.DataList
        let i = 1;
        this.tableData.forEach((element) => {
          element.sindex =
            (this.pagination.pageIndex - 1) * this.pagination.pagesize + i++;
        });
        this.pagination.totalCount = res.data.TotalCount
      }).finally(() => {
        this.loading = false;
      })
    },
    // //获取场景类型列表
    // getSceneList() {
    //   getDataDict({ type: 27 }).then(res => {
    //     this.sceneList = res.patterSetInfo
    //   })
    // },
    // //获取货品单位列表
    // getGoodsUnitList() {
    //   getDataDict({ type: 28 }).then(res => {
    //     this.goodsUnitList = res.patterSetInfo
    //   })
    // },

    // 关闭弹框
    cancelModel() {
      this.showEditDialog = false
    },
    // 发往运单库
    sendOrderStore() {
      if (this.selectedTableItemList.length <= 0) {
        this.$message({
          message: "请选择运单",
          type: "warning",
        });
      } else {
        this.$confirm('是否要提交此批运单？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 获取所有需要发送的settleID
          let arr = []
          this.selectedTableItemList.forEach(element => {
            arr.push(element.SettleID)
          });
          // 转为字符串传给后台
          let str = arr.join(',')
          const params = {
            settleIds: str
          }
          sendOrderStore(params).then(res => {
            this.$message({
              message: `提交成功，批次号为${res.data}`,
              type: "success",
            });
          })
          this.pagination.pageIndex = 1
          setTimeout(() => {
            this.getConstructionBillList()
          }, 100)
        }).catch(() => {

        });

      }
    },
    //tbale选中要发往运单库的运单
    tableSelectionChange(e) {
      this.selectedTableItemList = e

    },
  },
  created() {
    //获取全部运单列表
    this.getConstructionBillList()
  },

}
</script>

<style>
.constructionbillList .el-dialog {
  top: -10%;
}

.v-modal {
  z-index: 2000 !important;
}

.hide .el-upload--picture-card {
  display: none;
}

.constructionbillList .el-input.is-disabled .el-input__inner {
  color: #333;
}
</style>

<style scoped lang="scss">
@import '../../../../../assets/style/variable.scss';

.constructionbillList {
  .info {
    display: flex;
    font-size: 32px;
    align-items: center;
    margin-bottom: 10px;
  }

  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .el-form-item {
    margin-bottom: 14px;
  }

  .head {
    display: flex;

    .btn {
      vertical-align: top;
      margin: 15px 0 0 16px;
    }
  }

  .vehicleType {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
      padding-top: 28px !important;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .uploadArea {
      margin-top: 20px;

      .confirmInfoForm {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .hint {
      display: flex;
      justify-content: center;
    }

    .choose {
      display: flex;
      justify-content: center;
    }
  }

  .btns {
    margin-bottom: 20px;
  }

  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }

  .addForm {
    display: flex;
    flex-wrap: wrap;

    .el-form-item {
      width: 350px;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }

  .pagination {
    margin-top: 10px;
  }

}
</style>